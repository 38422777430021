import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon, { IconNames } from 'mineralsoft/components/Icon';
import Loader from 'mineralsoft/components/LoadingIndicator';

import BackgroundLayer from './backgroundLayer';
import StyledButton from './styles';
import { getColors } from './helpers';
import ButtonSizes from './sizes';

/**
 * @deprecated User MsButton instead of this component.
 */
function Button(props) {
  const {
    children,
    iconName,
    disabled,
    loadingText,
    loading,
    href,
    type,
    ...otherProps
  } = props;
  const [hovered, setHover] = useState(false);

  const icon = IconNames.includes(iconName) && (
    <Icon name={iconName} color={getColors(props, hovered).text} />
  );

  return (
    <StyledButton
      as={href ? 'a' : 'button'}
      href={href || undefined}
      type={!href ? type : undefined}
      disabled={disabled || loading}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      componentLoading={loading}
      hasLoadingText={!!loadingText}
      noChildren={React.Children.count(children) === 0 || children === ''}
      {...otherProps}
    >
      <div tabIndex="-1">
        <BackgroundLayer />
        {(!loading || !loadingText) && (
          // When loading without loadingText the loadingWrapper below
          // will be given absolute positioning, so we should still render
          // icon & text to set the width. When there is loading text, the
          // loadingWrapper is positioned statically, so this should be hidden.
          <React.Fragment>
            {icon}
            {children}
          </React.Fragment>
        )}
        {loading && (
          <div className="loadingWrapper">
            <Loader contextColor={getColors(props, hovered).bg} />
            { loadingText && <span style={{ marginLeft: '8px' }}>{loadingText}</span> }
          </div>
        )}
      </div>
    </StyledButton>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  /** The contents of the button, any react you'd like! */
  children: PropTypes.node,
  /** The name of the icon to display to the left of button text */
  iconName: PropTypes.string,
  /** small, or default */
  size: PropTypes.oneOf(Object.values(ButtonSizes)),
  /**
   * "primary" generally refers to whether it's the main action on that part of the page.
   * We never want two primary buttons next to each other. If you set primary on a button,
   * it'll be blue, unless it's also marked as destructive.
   */
  primary: PropTypes.bool,
  /** A more minimal version of buttons, without a border */
  minimal: PropTypes.bool,
  /**
   * The button will be red on hover, to indicate that this is a destructive action,
   * or red by default if it's a primary button
   */
  destructive: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * Set to true if this button is on soft fog. This simply adds shadows and depth to
   * the button, to help differentiate it against the darker background.
   */
  floating: PropTypes.bool,
  /** This will enable the button to expand to fill it's container (width only) */
  wide: PropTypes.bool,
  /**
   * Replaces button content with a loading indicator, without changing the width.
   * Displays loadingText if provided
   */
  loading: PropTypes.bool,
  /** Turns the button into an <a> without changing its styling. Can be used with onClick */
  href: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  /** An optional message to be displayed next to the loading indicator, while loading */
  loadingText: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
};

Button.defaultProps = {
  onClick: null,
  href: '',
  children: '',
  iconName: '',
  type: 'button',
  primary: false,
  minimal: false,
  destructive: false,
  size: ButtonSizes.default,
  disabled: false,
  floating: false,
  wide: false,
  loading: false,
  loadingText: '',
  style: undefined,
  title: undefined,
  id: undefined,
};

export default Button;
export { ButtonSizes };
